<template>
    <div class="justify-content-center align-items-center text-center">

        <h5>Son
            <b-form-input
                debounce="1500"
                style="width: 50px;text-align: center;display: inline-block;"
                v-model="numberOfTweetsToDisplay"></b-form-input>
            Tweet
        </h5>
        <!--
        <h5 style="margin-bottom: 0px">Veya</h5>
        <b-form-input class="text-center mb-3 mt-1 w-100"
                v-model="ozelLink" placeholder="Linki Buraya Yapıştırabilirsiniz..">
        </b-form-input>-->

        <div v-for="tweet in tweetler" :key="tweet">
            <b-jumbotron>
                <Tweet :id="tweet"
                       :options="{ lang: 'tr', align: 'center'}"
                       error-message="Tweet bulunamadı. Silinmiş olabilir mi? Sitede olduğundan emin olun.">
                    <b-spinner style="width: 3rem; height: 3rem;" label="Yükleniyor..."></b-spinner>
                </Tweet>
                <b-button @click="tweetiSec(tweet)"
                        class="w-50 mx-auto" block variant="success">Seç</b-button>
            </b-jumbotron>
        </div>
    </div>
</template>

<script>
    import { Tweet } from 'vue-tweet-embed'
    import http from '@/request'

    export default {
        name: 'tweetler',
        data() {
            return {
                numberOfTweetsToDisplay: 5,
                isLoadingAllTweets: false,
                tweetler: [],
                ozelLink: '',
            }
        },
        watch: {
            numberOfTweetsToDisplay: async function () {
                console.log('huhu')
                if (this.numberOfTweetsToDisplay > 20) {
                    alert('20 Dan Büyük Olması Sakıncalı. Twitter Rate Limit Problemi Var.' +
                        'Lütfen Link Kullanın')
                }
                else {
                    await this.tweetleriGetir()
                }
            },
            ozelLink: function () {
                console.log(this.ozelLink)
            }
        },
        methods: {
            tweetiSec(tweet) {
                this.$emit('tweet-sec', tweet)
            },
            async tweetleriGetir() {
                const { data } = await http.request({
                    url: 'tweet/GetTweets',
                    params: {
                        numberOfTweets: this.numberOfTweetsToDisplay
                    }
                });
                this.isLoadingAllTweets = true;
                this.tweetler = data;
            }
        },
        async mounted() {
           await this.tweetleriGetir()
        },
        components: {
            Tweet
        }
    }
</script>

<style scoped>

</style>
