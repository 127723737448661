import http from '@/request'
import {islem} from "@/islem"

export const RetweetVeyaLikeIslemiYap = (islemiYapanKisiId, yapilanIslem, tweetId, kullaniciSayisi, dakika) => {
    return http.request({
        url: 'tweet/RetweetVeyaLikeIslemi',
        method: 'post',
        params: {
            islemiYapanKisiId,
            yapilanIslem,
            tweetId,
            kullaniciSayisi,
            dakika
        }
    })
}

export const TweetCevapla = (sablonId, islemiYapanKisiId, kullaniciSayisi, dakika, islemYapilacakTweetId) => {
    return http.request({
        url: 'tweet/TweetAtmaIslemi',
        method: 'post',
        params: {
            yapilanIslem: islem.TWEETCEVAPLA,
            sablonId,
            islemiYapanKisiId,
            kullaniciSayisi,
            dakika,
            islemYapilacakTweetId
        }
    })
}


export const Tweetle = (sablonId, islemiYapanKisiId, kullaniciSayisi, dakika) => {
    return http.request({
        url: 'tweet/TweetAtmaIslemi',
        method: 'post',
        params: {
            yapilanIslem: islem.TWEET,
            sablonId,
            islemiYapanKisiId,
            kullaniciSayisi,
            dakika
        }
    })
}
