<template>
    <div>
        <Tweetler @tweet-sec="tweetSec" />

        <b-modal ref="tweetCevaplaModal"
                 title="Tweet Cevaplama İşlemi"
                 @ok="tweetCevapla()"
                 ok-title="Tamam"
                 cancel-title="İptal Et">
            <p>Tweet Attırma iznini <strong>{{this.tweetYazmaIzniVerenSayisi}}</strong> kişi vermiş.</p>

            <label class="mt-3" for="kullaniciSayisi">Kullanici Sayisi</label>
            <b-form-input id="kullaniciSayisi"
                          v-model="kullaniciSayisi"></b-form-input>

            <label class="mt-3" for="kacDakikadaYapilsin">Kaç Dakikada Yapılacak</label>
            <b-form-input id="kacDakikadaYapilsin"
                          v-model="kacDakikadaYapilacak"></b-form-input>
        </b-modal>
    </div>
</template>

<script>
    import Tweetler from "@/views/Admin/Tweetler"
    import {TweetAtmaIziniVerenSayisi} from "@/api/hesap"
    import {HataliMesajiniGoster, BasariliMesajiniGoster} from "@/messages"
    import {mapGetters} from "vuex"
    import {TweetCevapla} from "@/api/tweet"

    export default {
        name: "TweetCevapla",
        data() {
            return {
                tweetYazmaIzniVerenSayisi: 0,
                kullaniciSayisi: '',
                kacDakikadaYapilacak: '',
                islemYapilacakTweetId: null,
            }
        },
        computed: {
            ...mapGetters([
                'kisi'
            ])
        },
        methods: {
            tweetSec(tweetId) {
                this.islemYapilacakTweetId = tweetId
                this.$refs['tweetCevaplaModal'].show()
            },
            async tweetCevapla() {
                if (this.kullaniciSayisi === '')
                    return HataliMesajiniGoster("Kullanıcı Sayısı Boş Bırakılamaz!")

                if (this.kacDakikadaYapilacak === '')
                    return HataliMesajiniGoster("Dakikayı Boş Bırakamazsınız!");

                if (this.tweetYazmaIzniVerenSayisi < this.kullaniciSayisi)
                    return HataliMesajiniGoster("O Kadar izin veren kişi yok!");


                const res = await TweetCevapla(
                    this.$route.params.sablonId,
                    this.kisi.id,
                    this.kullaniciSayisi,
                    this.kacDakikadaYapilacak,
                    this.islemYapilacakTweetId
                )

                BasariliMesajiniGoster(res)

                this.$router.go(-1)
            }
        },
        async created() {
            this.tweetYazmaIzniVerenSayisi = await TweetAtmaIziniVerenSayisi()
        },
        components: {
            Tweetler
        }
    }
</script>

<style scoped>

</style>
